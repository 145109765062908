// extracted by mini-css-extract-plugin
export var CJARoot = "index-module--CJARoot--WqGaF";
export var bWarrper = "index-module--bWarrper--pvgVV";
export var banner = "index-module--banner--BZZ9c";
export var bannerBtnsClass = "index-module--bannerBtnsClass--aaass";
export var bannerDesc = "index-module--bannerDesc--7VLVB";
export var bannerTitle = "index-module--bannerTitle--S-r7n";
export var bannerWrapper = "index-module--bannerWrapper--biV71";
export var carousel = "index-module--carousel--BsUvi";
export var carouselWrapper = "index-module--carouselWrapper--AQx2J";
export var descClassName = "index-module--descClassName--yadP4";
export var enTitle = "index-module--enTitle--9DHPj";
export var item00 = "index-module--item00--0skNt";
export var item01 = "index-module--item01--bwUNZ";
export var itemContenWrapperClass = "index-module--itemContenWrapperClass--hXC3E";
export var itemMove1 = "index-module--itemMove1--jqE-b";
export var itemMove2 = "index-module--itemMove2--UvFRF";
export var itemMove3 = "index-module--itemMove3--nmYrv";
export var itemMove4 = "index-module--itemMove4--MWlxn";
export var linkIconClassName = "index-module--linkIconClassName--MjEOf";
export var logo_wall_en = "index-module--logo_wall_en---nCxC";
export var partContent = "index-module--partContent--Xk0ug";
export var section = "index-module--section--pWTHE";
export var tabItemClass = "index-module--tabItemClass--hrh79";
export var tabItemClassEn = "index-module--tabItemClassEn--d1Vnu";
export var tabsClass = "index-module--tabsClass--9X2ib";
export var tabsClassEn = "index-module--tabsClassEn--53WP6";
export var wWarrper = "index-module--wWarrper--mYTX1";