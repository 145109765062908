import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/en'
import {
  Banner,
  SummaryTitle,
  TabSwitcher,
  Button,
  ImagePreview,
  //   ScanCodeConsultButton,
  PageBottomCard,
  TestimonyCard,
  //   MoreResources,
} from 'components/index'
import Seo from 'components/EN/Seo'
// import Section from 'components/SolutionComponents/Section'
import { ButtonGroup } from 'components/Base/Button'
import GreenCard from 'components/Analysis/GreenCard'
import NewShowCard from 'components/engines/NewShowCard'
import SigningPlate from 'components/Solutions/SigningPlate'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

import * as styles from './index.module.less'
import CjaEnginesPreviewEn from './img/CjaEnginesPreviewEn.png'

const Cja = (props) => {
  const { data } = props
  const { cjaEnYaml } = data
  const { banner, part2, part3, part4, part5, part6 } = cjaEnYaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="Sensors Customer Journey Analytics｜SensorsData"
        description="Sensors Customer Journey Analytics helps businesses improve their data analysis capabilities, understand customer needs in depth, and optimize product and service experiences based on user feedback. This can lead to improved customer satisfaction and marketing effectiveness, and ultimately drive business performance growth."
        keywords="customer journey analysis, user need analysis, user behavior analysis, customer analysis, customer data analysis"
      />

      <div className={styles.CJARoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={banner?.title}
            desc={banner?.desc}
            buttons={banner?.buttons}
            enTitle={banner?.enTitle}
            className={classnames(styles.banner)}
            h1tag={true}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
            enTitleClassName={styles.enTitle}
          />
        </div>
        {/* 以客户旅程分析为基础，深入了解你的客户 */}
        <div className={styles.wWarrper}>
          <SummaryTitle
            title="Get to Know Your Customers Better through CJA"
            desc="From connection to extension, empowering enterprises to uncover potential opportunities"
          />
          <div className="lg:flex lg:justify-center md:mt-[73px] mt-[2.6rem] md:px-0 px-[2rem]">
            {part2?.stepArr?.map((item) => {
              return <NewShowCard className="lg:mx-[13px] mb-[10px] lg:mb-0" key={item?.title} data={item} />
            })}
          </div>
          <div className="flex justify-center mt-[30px] lg:mt-[60px]">
            <ButtonGroup>
              {part2.buttons.map((item, index) => {
                return (
                  <Button key={index} btnType="primary" ghost={item.ghost} href={item.href} target="_blank">
                    {item.text}
                  </Button>
                )
              })}
            </ButtonGroup>
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle
            title="Data Analysis is More than Just Statistic"
            desc="Deep dive into the customer journey to understand their needs"
          />
          <div className={styles.partContent}>
            <TabSwitcher
              data={part3.data}
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              longTitle
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClassEn}
              tabItemClass={isMb ? null : styles.tabItemClassEn}
            />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="Why Choose Sensors Data?" desc="Unlock the power of data" />
          <div className="lg:mt-[60px] mt-[26px] lg:w-[1200px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part4?.dataSource?.map((item) => {
              return <GreenCard className="mt-[14px] lg:mt-0" key={item?.title} dataSource={item} />
            })}
          </div>
          <ButtonGroup className="lg:mt-[60px] mt-[30px] text-center">
            <Button href="/api/client/user/router?demo=Retail" target="_blank" btnType="primary">
              Free Demo
            </Button>
            <Button href="/form/parade/" target="_blank" btnType="primary" ghost>
              Contact us
            </Button>
            {/* <ScanCodeConsultButton
              className="ml-[20px]"
              qrcode="https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              btnType="primary"
              ghost
            /> */}
          </ButtonGroup>
        </div>
        {/* 灵活下钻数据，赋能企业经营分析能力 */}
        <section className={styles.section}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...part6} />
          </div>
          <div
            className={classnames(
              'md:h-[100px] h-[50px] lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full',
              styles.carouselWrapper,
            )}
          >
            <ScrollLogoWall
              className={classnames(' mt-[20px] ', styles.logo_wall_en)}
              maskVisible={false}
              title={part6.title}
              logo_wall={part6.icons}
            />
          </div>
        </section>
        <div className={styles.wWarrper}>
          <SummaryTitle
            title="Uncover Hidden Insights from Customer Journey"
            desc="Deliver trusted insights based on comprehensive analytics features"
          />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview imageUrl={CjaEnginesPreviewEn} language="en" alt="客户旅程优化引擎" />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center')}>
            <Button href="/api/client/user/router?demo=Retail" target="_blank" btnType="primary">
              Free Demo
            </Button>
            <Button href="/form/parade/" target="_blank" btnType="primary" ghost>
              Contact us
            </Button>
            {/* <ScanCodeConsultButton
              className="lg:mx-[20px] ml-[16px] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/wxcode.png"
              size="small"
              target="_blank"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              ghost
              buttonType="primary"
            /> */}
          </ButtonGroup>
        </div>
        <div className={styles.bWarrper} style={{ paddingBottom: '0px' }}>
          <SummaryTitle title="Discover Other Products" />
          <SigningPlate
            className="lg:block"
            dataSource={part5}
            linkIconClassName={styles.linkIconClassName}
            descClassName={styles.descClassName}
            // enTitle={part5?.enTitle}
          />
          <div className="lg:mt-[40px] mt-[2.6rem]">
            <PageBottomCard
              title="Ready to Get Started？"
              desc="See how Sensors Data can help you make better customer journey"
              leftButtonText="Contact Sales"
              leftButtonHref="/form/parade/"
              type="newBg"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    cjaEnYaml {
      banner {
        title
        desc
        buttons {
          text
          href
          btnType
        }
      }
      part2 {
        stepArr {
          title
          desc
          img {
            publicURL
          }
          mbImg {
            publicURL
          }
        }
        buttons {
          text
          href
          ghost
        }
      }
      part3 {
        data {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            buttons {
              btnType
              href
              text
              target
            }
            introductionList {
              content
            }
          }
        }
      }
      part4 {
        dataSource {
          title
          desc
          content
        }
      }
      part5 {
        left {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
        right {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
      }
      part6 {
        title
        text
        signature
        imgUrl {
          publicURL
        }
        icons {
          publicURL
        }
      }
    }
  }
`

export default Cja
